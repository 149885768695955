import { Gk2500Service } from "../Gk2500.service";
import { dictTerm } from "./dictTerm";

export class dictVocabulary {
    code: string;
    name: string;
    description: string;



    constructor(pCode: string, pName: string, private svc: Gk2500Service) {
        this.code = pCode;
        this.name = pName;
    }

    public async GetTerms(): Promise<dictTerm[]> {
        var termsList = await this.svc.ServiceGet<dictTerm[]>('voc/' + this.code + "/terms");
        return termsList;
    }

}