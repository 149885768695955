import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.css']
})
export class ListFieldComponent implements OnInit {

  @Input("value") model: string[];
  @Output("valueChange") modelChange = new EventEmitter<string[]>();

  @Input() edit: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  AddValue() {
    if (!this.model) {
      this.model = [];
    }
    this.model.push("");
  }

  DeleteValue(synIndex: number) {
    this.model.splice(synIndex, 1);
  }

  SetValue(synIndx: number, newValue: any) {
    this.model[synIndx] = newValue;
  }

}
