import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AgeScale } from 'src/app/Model/matrixLegend/age-scale';
import { AgeScaleItem } from 'src/app/Model/matrixLegend/age-scale-item';
import { HeadScale } from 'src/app/Model/matrixLegend/head-scale';
import { HeadScaleItem } from 'src/app/Model/matrixLegend/head-scale-item';
import { Legend } from 'src/app/Model/matrixLegend/legend';
import { LegendCube } from 'src/app/Model/matrixLegend/legend-cube';

@Component({
  selector: 'app-matrix-legend',
  templateUrl: './matrix-legend.component.html',
  styleUrls: ['./matrix-legend.component.css']
})
export class MatrixLegendComponent implements OnInit {
  /// View Setup 
  public scaleW: number = 40;
  public scaleH: number = 50;
  private headW: number = 40;
  private headH: number = 40;

  public ybShow: boolean = false;
  public ybx = 0;
  public yby = 0;
  public ybText3: string;
  public ybText2: string;
  public ybText1: string;


  public viewBoxScale = '0 0 1000 1000'
  public viewBoxHead = '0 0 1000 1000'
  public viewBoxMain = '0 0 1000 1000'
  public viewBoxCorner = '0 0 1000 1000'

  public offsetX = 0;
  public offsetY = 0;
  public zoomIndx: number = 1;

  @Input() scale: AgeScale;
  @Input() head: HeadScale;

  tempText: any;
  _legend: Legend;
  selectedCube: LegendCube;
  selectedName: string;
  selectedItem: HeadScaleItem | LegendCube | AgeScaleItem;

  @Input() set legend(pLegend: Legend) {
    this._legend = pLegend;
    this.update();
  }
  get legend(): Legend {
    return this._legend;
  }

  @Output() cubeSelected = new EventEmitter<LegendCube>();

  mmoveMode: boolean;
  mmoveX: number;
  mmoveY: number;

  @ViewChild('mainSvg') mainSvg: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.update();
  }

  update() {
    if (this.legend) {
      this.legend.CalculatePosition(this.scale, this.head);
      this.legend.CalculateCooredinates(this.headW, this.scaleH)
    }
    this.updateViewBox();
  }

  test(event, item: HeadScaleItem) {
    console.log("test", event);
    if (event.shiftKey) {
      item.MinSize--;
    } else {
      item.MinSize++;
    }
    this.head.ReCalculate();
    this.update();
    //this.updateViewBox();
  }

  updateViewBox() {
    var h = this.mainSvg.nativeElement.clientHeight;
    var w = this.mainSvg.nativeElement.clientWidth;
    this.viewBoxScale = "" + 0 + " " + (this.offsetY) + " " +
      (w * this.zoomIndx) + " " + (h * this.zoomIndx);

    this.viewBoxHead = "" + (this.offsetX) + " " + 0 + " " +
      (w * this.zoomIndx) + " " + (h * this.zoomIndx);

    this.viewBoxMain = "" + (this.offsetX) + " " + (this.offsetY) + " " +
      (w * this.zoomIndx) + " " + (h * this.zoomIndx);
    this.viewBoxCorner = "0 0 " + (w * this.zoomIndx) + " " + (h * this.zoomIndx);
  }

  selectCube(item: LegendCube, event: any) {
    //console.log(item);
    this.selectedCube = item;
    this.ybShow = true;
    this.ybx = item.cx + item.cw / 2;
    this.yby = item.cy + item.ch / 2;
    this.ybText1 = item.Index;
    this.ybText2 = item.Name;
    this.ybText3 = "Age: " + item.Age1 + " - " + item.Age2;
    this.cubeSelected.emit(item);
  }

  ResetSelectCube() {
    this.selectedCube = null;
    this.ybShow = false;
    this.ybx = 0;
    this.yby = 0;
  }

  hoverBlock(item: LegendCube | AgeScaleItem | HeadScaleItem) {
    this.selectedName = item.Name;
    this.selectedItem = item;
  }

  onMouseWheel($event) {
    var dxCorrection = $event.offsetX * this.zoomIndx;
    var dyCorrection = $event.offsetY * this.zoomIndx;

    this.zoom(-$event.deltaY);

    dxCorrection = dxCorrection - $event.offsetX * this.zoomIndx;
    dyCorrection = dyCorrection - $event.offsetY * this.zoomIndx;

    this.move(-dxCorrection, -dyCorrection);
    $event.stopPropagation();
    $event.preventDefault();
  }

  zoom(num: number) {
    if (num < 0) {
      this.zoomIndx *= 1.1;
    }
    if (num > 0) {
      this.zoomIndx *= 0.9;
    }
    if (num == 0) {
      this.zoomIndx = 1;
    }
    this.updateViewBox();
  }

  move(dX: number, dY: number) {
    this.offsetX -= dX;
    this.offsetY -= dY;

    if (this.offsetX < -200) {
      this.offsetX = -200;
    }
    if (this.offsetY < -100) {
      this.offsetY = -100;
    }
    this.updateViewBox();
  }

  onMouseDownCell($event) {
    console.log("mouse cell");
    $event.stopPropagation();
    $event.preventDefault();
  }
  onMouseDown($event) {
    this.mmoveMode = true;
    this.mmoveX = $event.x;
    this.mmoveY = $event.y;
    //$event.preventDefault();
  }

  onMousemove($event) {
    if (this.mmoveMode) {
      var dx = ($event.x - this.mmoveX) * this.zoomIndx;
      var dy = ($event.y - this.mmoveY) * this.zoomIndx;
      this.move(dx, dy);
      this.mmoveX = $event.x;
      this.mmoveY = $event.y;
      $event.stopPropagation();
      $event.preventDefault();
    }
  }
  onMouseUp($event) {
    this.mmoveMode = false;

  }

}
