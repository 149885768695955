import { Component, OnInit } from '@angular/core';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-audit-view',
  templateUrl: './audit-view.component.html',
  styleUrls: ['./audit-view.component.css']
})
export class AuditViewComponent implements OnInit {

  public auditRecords: any[] = [];

  constructor(private maps2500: Maps2500Service) { 
    this.LoadAuditRecords();
  }
  
  
  async LoadAuditRecords() {
    this.auditRecords = await this.maps2500.GetAuditRecords();  
  }

  ngOnInit(): void {

  }

}
