import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { dictTerm } from './Model/dictTerm';
import { dictVocabulary } from './Model/dictVocabulary';

@Injectable({
  providedIn: 'root'
})
export class Gk2500Service {
  GetVocabularyList(): dictVocabulary[] {
    return [
      new dictVocabulary("age", "Возраст", this),
      new dictVocabulary("gdk", "ГДК", this),
      new dictVocabulary("tectl", "Тектоник", this),
      new dictVocabulary("krap", "Крап", this)
    ];

  }


  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private oidc: OidcSecurityService) {

  }

  async UpdateShapeFileStatic(): Promise<boolean> {
    var url = this.baseUrl + "TK2500/updateBasea";
    await this.HttpGet(url);
    return true;
  }

  async UpdateCorrelationDynamic(): Promise<boolean> {
    var url = this.baseUrl + "TK2500/updateDynamicCorr";
    await this.HttpGet(url);
    return true;
  }



  async ServiceGet<T>(urlRelative: string): Promise<T> {
    var url = this.baseUrl + 'TK2500/' + urlRelative;
    var termsList = await this.HttpGet<T>(url);
    return termsList;
  }

  public async HttpGet<T>(url: string): Promise<T> {
    var accessToken = this.getToken();
    var ans = this.http.get<T>(url, { headers: { "Authorization": "Bearer " + accessToken } }).toPromise();
    return ans;
  }
  private getToken() {
    return this.oidc.getToken();
  }


}
