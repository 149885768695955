import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CounterComponent } from './Controller/Test/counter/counter.component';
import { FetchDataComponent } from './Controller/Test/fetch-data/fetch-data.component';
import { HomeComponent } from './Controller/home/home.component';
import { VocabularyComponent } from './Controller/tk2500/Vocabulary/Vocabulary.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppMaterialModule } from './app-material.module';
import { PublishComponent } from './Controller/tk2500/Publish/Publish.component';
import { TranTComponent } from "./Controller/tran-t/tran-t.component";
import { StructComponent } from './Controller/system/struct/structMain/structMain.component';
import { DictionaryComponent } from './Controller/system/vocabulary/dictionary/dictionary.component';
import { AuditViewComponent } from './Controller/system/audit/audit-view/audit-view.component';
import { CorrelationsListComponent } from './Controller/system/correlation/correlations-list/correlations-list.component';
import { VersionsMainComponent } from './Controller/system/versions/versions-main/versions-main.component';
import { CorrTableMainComponent } from './Controller/CorrTable/corr-table-main/corr-table-main.component';


@NgModule({
    declarations: [
        HomeComponent,
        VocabularyComponent,
        CounterComponent,
        FetchDataComponent
    ],
    imports: [
        AppMaterialModule,
        BrowserModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'counter', component: CounterComponent },
            { path: 'fetch-data', component: FetchDataComponent },
            { path: 'tk2500/publish', component: PublishComponent },
            { path: 'tk2500/voc', component: VocabularyComponent },
            { path: 'trant/:table', component: TranTComponent },
            { path: 'maps2500/struct', component: StructComponent },
            { path: 'maps2500/dictionary', component: DictionaryComponent },
            { path: 'maps2500/correlation', component: CorrelationsListComponent },
            { path: 'maps2500/versions', component: VersionsMainComponent },
            { path: 'maps2500/audit', component: AuditViewComponent },

            { path: 'corrtable/main', component: CorrTableMainComponent },


            // { path: 'trant/:id', component: Edit_TrantComponent , outlet:"trant-details"}

        ], { useHash: true }),],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }
