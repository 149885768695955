import { Component, Input, OnInit } from '@angular/core';
import { AgeScale } from 'src/app/Model/matrixLegend/age-scale';
import { HeadScale } from 'src/app/Model/matrixLegend/head-scale';
import { Legend } from 'src/app/Model/matrixLegend/legend';
import { LegendTable, LegendTableColumn } from 'src/app/Model/matrixLegend/legend-table';
import { Maps2500Service } from 'src/app/Services/maps2500.service';
import { MatrixLegendComponent } from '../matrix-legend/matrix-legend.component';
import { LegendCube } from 'src/app/Model/matrixLegend/legend-cube';

@Component({
  selector: 'app-corr-table-main',
  templateUrl: './corr-table-main.component.html',
  styleUrls: ['./corr-table-main.component.css']
})
export class CorrTableMainComponent implements OnInit {
  scale: AgeScale;
  head: HeadScale;
  legend: Legend;

  public selectedColumn: LegendTableColumn = null;
  public maxCount: number = 1000;

  public map:any; /// wega window

  public tablesList: LegendTable[] = [];
  public selectedTable: LegendTable = null;

  @Input("matrixLegend") matrixLegend: MatrixLegendComponent;

  constructor(private maps2500: Maps2500Service) {

  }

  async ngOnInit() {
    await this.LoadAwaliableLegends();
    if (this.matrixLegend) {
      this.matrixLegend.update();
    }
  }

  async LoadAwaliableLegends() {
    this.tablesList = await this.maps2500.GetMatrixLegends();
  }

  async loadTestData() {
    this.scale = await AgeScale.LoadScale("/assets/startAgesSL.json");
    this.head = await HeadScale.LoadHead("/assets/region.json");
    this.legend = await Legend.Load("/assets/cubes.json", "Name", "MinAge", "MaxAge", "Region");
  }
  async loadMapData(table: LegendTable, column: LegendTableColumn) {
    this.scale = null;
    this.head = null;
    this.legend = null;

    this.scale = await AgeScale.LoadScaleFromDict(this.maps2500, "StratAgeOSS2021", table.scaleDepth);
    this.head = await this.maps2500.GetMatrixRegion(table.code, column.code);
    this.legend = await this.maps2500.GetMatrixCube(table.code, column.code, this.maxCount);
  }

  async OpenMapAndLink() {
    if (this.map && !this.map.closed) {
      this.map.focus();
      return;
    }
    var url = this.selectedTable.mapUrl;
    this.map = window.open(url);
    this.map.focus();
    //if (document.referrer.includes("wega")) this.map = window.opener;
  }

  public onCubeSelected(selectedCube : LegendCube)
  {
    this.OpenMapAndLink();
    this.SetWebMapFilter(selectedCube);
    console.log("(corr table main) select cube ", selectedCube);
  }

  SetWebMapFilter(selectedCube: LegendCube) {
    if (!this.map || this.map.closed) {
      console.error("SetWebMapFilter after OpenMapAndLink and still no window");
      return; /// что-то точно не так.
      //alert("нужно открыть окно с вегой");
      //this.map = window.open(Config.MapUrl);
    }
    let res = new Array();
    let complexNamesList = new Array();
    let zonesList = new Array();
    let FaciiList = new Array();
    let containsNullFacii = false; // в списке есть кубы без фации. В этом случае фильтр (FACCI IN (1,2,3)) задавать нельзя - пустые не найдутся

    var filterField = this.selectedColumn.webMapQuery;
    var filterValue = selectedCube.Region;
    debugger;

    let msg = {
      type: 'filterWegaMap',
      mapId: this.selectedTable.mapId,
      filter: []
    }

    msg.filter.push({ field: filterField, condition: "=", value: filterValue });
    msg.filter.push({ field: "COMPLEX_AGE", condition: "=", value: selectedCube.Age1 });
    // msg.filter.push({ field: filterField, condition: "in", value: complexNamesList.join(",") });

    const setWebMapFilter = () => { setTimeout(() => { this.map.postMessage(msg, '*'); }, 1000) };
    this.map.focus();
    setWebMapFilter();
    // this.map.addEventListener('load', setWebMapFilter);
    // else {
    //   this.map.removeEventListener('load', setWebMapFilter);
    // }
  }
}