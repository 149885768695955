import { Component, Input, OnInit } from '@angular/core';
import { Layer } from 'src/app/Model/System/layer';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.css']
})
export class LayerComponent implements OnInit {
  edit: boolean = true;
  @Input() public layer?: Layer = null;

  constructor() { }

  ngOnInit(): void {
  }

}
