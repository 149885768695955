import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table'  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
    ],
    exports: [
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
    ]
})

export class AppMaterialModule { }