import { Component, Input, OnInit } from '@angular/core';
import { Field } from 'src/app/Model/System/field';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {
  edit: boolean = true;
  @Input() public field?: Field = null;

  constructor() { }

  ngOnInit(): void {
  }

}
