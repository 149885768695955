import { Component, Input, OnInit } from '@angular/core';
import { Correlation } from 'src/app/Model/System/correlation';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-correlation-details',
  templateUrl: './correlation-details.component.html',
  styleUrls: ['./correlation-details.component.css']
})
export class CorrelationDetailsComponent implements OnInit {

  @Input() correlation?: Correlation = null

  constructor(private maps2500: Maps2500Service) {

  }

  ngOnInit(): void {
  }

}
