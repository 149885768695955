import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Params } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { TranT } from "../Model/trant";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TranTServiceService {

  private url: string;
  private table: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private oidc: OidcSecurityService,
              private route: ActivatedRoute
  ) {
    // отслеживаем изменение параметра id
    this.table = this.route.snapshot.paramMap.get('table')
    route.paramMap.subscribe(params=>{
      this.table=params.get('table');
      console.log("store", params);
      console.log("table", this.table);
    });

    this.url = this.baseUrl + 'api/taxon/' ;
  }
  GetUrl():string{
    return this.url + this.table + '/';
  }
  Add(taxon: TranT): Observable<TranT> {
    return this.http.post<TranT>(this.GetUrl(), taxon,
      { headers: { "Authorization": "Bearer " + this.oidc.getToken() } })
  }

  Update(taxon: TranT): Observable<TranT> {
    return this.http.put<TranT>(this.GetUrl() + taxon.taxonId, taxon,
      { headers: { "Authorization": "Bearer " + this.oidc.getToken() } })
  }
  Delete(taxonID: number): Observable<TranT> {
    return this.http.delete<TranT>(this.GetUrl() + taxonID,
      { headers: { "Authorization": "Bearer " + this.oidc.getToken() } })
  }

  GetByID(id): Observable<TranT> {
    return this.http.get<TranT>(this.GetUrl() + id, { headers: { "Authorization": "Bearer " + this.oidc.getToken() } })
  }
}
