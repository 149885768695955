export class HeadScaleItem {
    public itemsList: HeadScaleItem[] = [];
    public Id: number;
    public ParentId: number;
    public Name: string;

    // Calculated parameters for visualising
    public MinSize: number = 1;

    public MaxWidth: number;
    public MaxDepth: number;
    public level: number;
    public offsetX: number = 0;


    constructor(pJson: any) {
        //Object.assign(this, pJson)
        if (typeof pJson == "string") {
            this.Name = pJson;
            return;
        }

        this.Id = pJson["Id"];
        this.ParentId = pJson["ParentId"];
        this.Name = pJson["Name"];
        this.itemsList = [];
        if (pJson["Items"] && Array.isArray(pJson["Items"])) {
            for (var item of pJson["Items"]) {
                var newItem = new HeadScaleItem(item);
                this.itemsList.push(newItem);
            }
        }
        this.MinSize = pJson["MinSize"] ?? 1;
    }

    public CalculateMaxDepth(pLevel = 0): number {
        this.level = pLevel;
        var maxDepth = 0;
        if (this.itemsList && this.itemsList.length > 0) {
            for (var item of this.itemsList) {
                var itemDepth = item.CalculateMaxDepth(pLevel + 1);
                if (itemDepth > maxDepth) {
                    maxDepth = itemDepth;
                }
            }
        }

        this.MaxDepth = maxDepth + 1;
        return maxDepth + 1;
    }

    public CalculateMaxWidth(offset: number = 0): number {
        this.offsetX = offset;
        if (this.itemsList && this.itemsList.length > 0) {
            var width = 0;
            for (var item of this.itemsList) {
                var itemWidth = item.CalculateMaxWidth(offset + width);
                width += itemWidth;
            }
            this.MaxWidth = width;
            if (this.MinSize > this.MaxWidth) this.MaxWidth = this.MinSize;
        } else {
            this.MaxWidth = this.MinSize;
        }
        return this.MaxWidth;

    }


}
