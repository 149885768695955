import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Layer } from 'src/app/Model/System/layer';
import { Map } from 'src/app/Model/System/map';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-select-map-layer',
  templateUrl: './select-map-layer.component.html',
  styleUrls: ['./select-map-layer.component.css']
})
export class SelectMapLayerComponent implements OnInit {

  @Input() layerId: number = 0;
  @Output() layerIdChange = new EventEmitter<number>();

  public mapsList: Map[] = [];
  public selectedMap: Map = null;
  public LayersList: layerIdName[] = [];
  public selectedLayer: layerIdName = null;

  constructor(private maps2500: Maps2500Service) {
    this.LoadMapsList();
  }

  async LoadMapsList() {
    this.mapsList = await this.maps2500.GetMapsList();
  }

  async onSelectMap(map: Map) {
    var layersList = await this.maps2500.GetLayersList(map);
    this.LayersList = [];
    this.AddHierarchicalList(layersList, 0);

  }
  AddHierarchicalList(layersList: Layer[], level: number) {
    for (var layer of layersList) {

      this.LayersList.push(new layerIdName(layer, level));
      if (layer.items && layer.items.length > 0) {
        this.AddHierarchicalList(layer.items, level + 1);
      }
    }
  }

  async onSelectLayer(layer: layerIdName) {
    this.layerIdChange.emit(layer.id);
  }

  ngOnInit(): void {
  }

}

class layerIdName {
  id: number;
  name: string;

  constructor(layer: Layer, level: number) {
    this.id = layer.layerId;
    var prefix = ".. ".repeat(level);
    this.name = prefix + layer.layerName;
  }
}
