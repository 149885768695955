import { HeadScaleItem } from "./head-scale-item";

export class HeadScale {
    ScaleName: string;
    itemsRoot: HeadScaleItem[] = [];
    itemsList: HeadScaleItem[] = [];

    constructor(pJson) {
        this.ScaleName = pJson["Name"] ?? "-";
        var items = [];
        if (pJson["Items"] && Array.isArray(pJson["Items"])) items = pJson["Items"];
        if (Array.isArray(pJson)) items = pJson;

        this.itemsRoot = items.map(itemJson => new HeadScaleItem(itemJson));
        this.itemsList = this.BuildItemsList(this.itemsRoot);

        this.ReCalculate();
    }

    ReCalculate() {
        var offset = 0;
        var maxDepth = 0;
        this.itemsRoot.forEach(element => {
            offset += element.CalculateMaxWidth(offset);
            maxDepth = Math.max(maxDepth, element.CalculateMaxDepth());
        });
    }

    GetByName(pRegionName: any): HeadScaleItem {
        for (var item of this.itemsList) {
            if (item.Name == pRegionName) {
                return item;
            }
        }
        return null;
    }

    SetMaxColumnIndex(pRegionName: string, pColumnSize: number) {
        var region = this.GetByName(pRegionName);
        if (region && pColumnSize > region.MinSize) {
            region.MinSize = pColumnSize;
        }
    }

    BuildItemsList(itemsRoot: HeadScaleItem[]): HeadScaleItem[] {
        var ans = [];
        for (var item of itemsRoot) {
            ans.push(item);
            var sublist = this.BuildItemsList(item.itemsList);
            if (sublist && sublist.length > 0) {
                for (var subitem of sublist) {
                    ans.push(subitem);
                }
            }
        }
        return ans;
    }

    BuildItemsTree(itemsList: HeadScaleItem[]): HeadScaleItem[] {
        var root = [];
        for (var item of itemsList) {
            if (item.ParentId) {
                var parentItem = itemsList.find((tItem) => tItem.Id == item.ParentId);
                parentItem.itemsList.push(item);
            } else {
                root.push(item);
            }
        }
        return root;
    }

    static async LoadHead(pJsonUrl: string): Promise<HeadScale> {
        var query = await fetch(pJsonUrl);
        var pJsonObject = await query.json();
        var ans = new HeadScale(pJsonObject);
        return ans;
    }

}
