import { Component, Input, OnInit } from '@angular/core';
import { VocabularyExport } from 'src/app/Model/System/vocabularyExport';

@Component({
  selector: 'app-export-form',
  templateUrl: './export-form.component.html',
  styleUrls: ['./export-form.component.css']
})
export class ExportFormComponent implements OnInit {

  @Input() public exportGroup?: VocabularyExport = null;

  constructor() { }

  ngOnInit(): void {
  }

}
