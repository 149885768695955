import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vocabulary } from 'src/app/Model/System/vocabulary';
import { VocabularyExport } from 'src/app/Model/System/vocabularyExport';

@Component({
  selector: 'app-vocabulary-form',
  templateUrl: './vocabulary-form.component.html',
  styleUrls: ['./vocabulary-form.component.css']
})
export class VocabularyFormComponent implements OnInit {

  @Input() public vocabulary?: Vocabulary = null;

  @Input("selectedGroup") public selectedGroup: VocabularyExport;
  @Output("selectedGroupChange") public selectedGroupChange = new EventEmitter<VocabularyExport>();

  constructor() { }

  ngOnInit(): void {
  }

  AddExport() {
    console.log("AddExport")
    if (!this.vocabulary.vocabularyExports) {
      this.vocabulary.vocabularyExports = [];
    }
    this.vocabulary.vocabularyExports.push(new VocabularyExport())

  }

  public SelectExportGroup(exp: VocabularyExport) {
    console.log(exp);
    this.selectedGroup = exp;
    this.selectedGroupChange.emit(exp);
  }

}
