import { HttpClient } from '@angular/common/http';
import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Gk2500Service as Gk2500Service } from 'src/app/Gk2500.service';

import { dictVocabulary } from '../../../Model/dictVocabulary';
import { dictTerm } from '../../../Model/dictTerm';


@Component({
  selector: 'app-Vocabulary',
  templateUrl: './Vocabulary.component.html',
  styleUrls: ['./Vocabulary.component.css'],
})
export class VocabularyComponent implements OnInit {
  termsListDS: MatTableDataSource<dictTerm>;
  termsList: dictTerm[];
  displayedColumns: string[] = ['code', 'order', 'bcode', 'name'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  statusUpdate: string;
  vocList: dictVocabulary[];

  constructor(
    private http: HttpClient,
    private svc: Gk2500Service,
    @Inject('BASE_URL') private baseUrl: string,
    private oidc: OidcSecurityService) {
  }

  ngOnInit() {
    this.vocList = this.svc.GetVocabularyList();
  }

  updateArcGIsDictionaries() {
    var accessToken = this.oidc.getToken();
    var url = this.baseUrl + "TK2500/voc/updateArcGIS";

    this.statusUpdate = "Выполняется обновление";
    this.http.get<object>(url, {
      headers: { "Authorization": "Bearer " + accessToken }
    }).subscribe(result => {
      this.statusUpdate = "Обновление словарей успешно:"
      this.statusUpdate += "\r\n" + JSON.stringify(result, null, ' ');
    }, error => {
      this.statusUpdate = "Ошибка обновления:"
      this.statusUpdate += "\r\n" + JSON.stringify(error, null, ' ');
      console.error(error)
    });

  }

  async loadDict(voc: dictVocabulary) {
    var result = await voc.GetTerms();

    this.termsList = result;
    this.termsListDS = new MatTableDataSource(result);
    this.termsListDS.sort = this.sort;
  }

}
