export class TranT {
    taxonId: number;
    parentTaxonId: number | null;
    orderNum: number | null;
    lvl: number | null;
    fullName: string;
    name: string;
    rang: string;
    overlap: string;
    authCode: string;
    descr: string;
    editUser: string;
    editDate: string | null;
}