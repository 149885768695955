import { AgeScale } from "./age-scale";
import { HeadScale } from "./head-scale";
import { LegendCube } from "./legend-cube";

export class Legend {
    public cubes: LegendCube[] = [];


    constructor(pJson: any, pFieldName: string, pFieldAge1: string, pFieldAge2: string, pFieldRegion: string) {
        var cubesList = [];
        if (pJson["Items"] && Array.isArray(pJson["Items"])) cubesList = pJson["Items"];
        if (Array.isArray(pJson)) cubesList = pJson;

        for (var itemJson of cubesList) {
            var item = new LegendCube(itemJson, pFieldName, pFieldAge1, pFieldAge2, pFieldRegion);
            this.cubes.push(item);
        }
    }


    CalculateInColumnIntersections(scale: AgeScale, head: HeadScale) {
        for (var cube of this.cubes) {
            cube.minAgeIndex = scale.GetMinByName(cube.Age1);
            cube.maxAgeIndex = scale.GetMaxByName(cube.Age2);
            cube.showInMatrx = (cube.minAgeIndex >0  && cube.maxAgeIndex >0);
        }

        for (var cube of this.cubes) {
            /// находим другие кубики в этой же колонке/
            var regionCubes = this.cubes.filter(c => c != cube && c.Region == cube.Region && this.IsAgeIntersected(c, cube, scale));
            if (regionCubes.length > 0) {
                var ocupiedColumnIndexes = regionCubes.map(c => c.ColumnIndex);
                var bestIndex = 0;
                while (ocupiedColumnIndexes.indexOf(bestIndex) != -1) bestIndex++;
                cube.ColumnIndex = bestIndex
                // console.log("CalculateInColumnIntersections", cube, ocupiedColumnIndexes, regionCubes)
                head.SetMaxColumnIndex(cube.Region, bestIndex + 1);
            }
        }

    }
    IsAgeIntersected(cube1: LegendCube, cube2: LegendCube, scale: AgeScale): any {
        return (cube1.maxAgeIndex > cube2.minAgeIndex && cube2.maxAgeIndex > cube1.minAgeIndex);
    }
    CalculatePosition(scale: AgeScale, head: HeadScale) {
        this.CalculateInColumnIntersections(scale, head);
        head.ReCalculate();
        for (var cube of this.cubes) {
            cube.CalculatePosition(scale, head);
        }
    }

    CalculateCooredinates(sizeW: number, sizeH: number) {
        for (var cube of this.cubes) {
            cube.CalculateCoordinates(sizeW, sizeH);
        }
    }

    static async Load(pJsonUrl: string, pFieldName: string, pFieldAge1: string, pFieldAge2: string, pFieldRegion: string): Promise<Legend> {
        var query = await fetch(pJsonUrl);
        var pJsonLegend = await query.json();
        var newLegend = new Legend(pJsonLegend, pFieldName, pFieldAge1, pFieldAge2, pFieldRegion);
        return newLegend;
    }
}


