import { Component, Input, OnInit } from '@angular/core';
import { Map } from 'src/app/Model/System/map';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @Input() public map?: Map = null;
  edit: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
