import { MapVersion } from "./map-version";

export class Map {
    mapCode: string;
    mapDescr: string;
    mapId: number;
    mapName: string;
    ver_work: MapVersion;
    ver_operative: MapVersion;
    ver_official: MapVersion;
    ver_nrs: MapVersion;
}
