import { DictTerm } from "./dict-term";
import { VocabularyExport } from "./vocabularyExport";

export class Vocabulary {
    gisVocName: string
    isHierarchical: string
    lastChange: Date
    lastUpdate: Date
    vocCode: number;
    vocId: string
    parentVocId: string
    vocName: string
    vocTablename: string
    vocType: string

    termsList: DictTerm[];
    vocabularyExports: VocabularyExport[];

    // Временно: для совместимости с текущей логикой.
    dictTerms: any[];
}
