import { asNativeElements } from "@angular/core";
import { Maps2500Service } from "src/app/Services/maps2500.service";
import { Vocabulary } from "../System/vocabulary";
import { AgeScaleItem } from "./age-scale-item";

export class AgeScale {
    ScaleName: string;
    itemsRoot: AgeScaleItem[] = [];
    itemsList: AgeScaleItem[] = [];

    constructor(pJson: Vocabulary | any, maxLevel: number = 0) {
        this.ScaleName = pJson.vocName ?? pJson["Name"];
        var itemsList = pJson.termsList ?? pJson["ScaleItemModels"];

        this.itemsList = itemsList.map(itemJson => new AgeScaleItem(itemJson));
        this.itemsRoot = this.BuildItemsTree(this.itemsList);
        if (maxLevel > 0) {
            this.LimitTreeDepth(this.itemsRoot, maxLevel);
        }
        this.ReCalculate();

    }

    LimitTreeDepth(itemsList: AgeScaleItem[], maxLevel: number, currentLevel: number = 0) {
        for (var item of itemsList) {
            if (currentLevel > maxLevel) {
                item.itemsList = null;
            }
            else {
                this.LimitTreeDepth(item.itemsList, maxLevel, currentLevel + 1);
            }
        }
    }

    ReCalculate() {
        var offset = 0;
        var maxDepth = 0;
        this.itemsRoot.forEach(element => {
            offset += element.CalculateMaxWidth(offset);
            maxDepth = Math.max(maxDepth, element.CalculateMaxDepth());
        });
    }

    SetMaxColumnIndex(pRegionName: string, pColumnSize: number) {
        var region = this.GetByName(pRegionName);

        if (region && pColumnSize > region.MinSize) {
            region.MinSize = pColumnSize;
        }
    }

    GetByName(pRegionName: string): AgeScaleItem {
        if (!pRegionName) return;
        var cleanRegionName = pRegionName?.toLocaleLowerCase().trim().replace(",", "");
        for (var item of this.itemsList) {
            if (item.Name && item.Name.toLocaleLowerCase() == cleanRegionName) {
                return item;
            }

            if (item.synonymsList) {
                if (item.synonymsList.indexOf(cleanRegionName) != -1) {
                    return item;
                }
            }
        }
        return null;
    }

    GetMinByName(pRegionName: string): number {
        var age = this.GetByName(pRegionName);
        if (age) {
            return age.offsetX;
        } else {
            return 0;
        }
    }
    GetMaxByName(pRegionName: string): number {
        var age = this.GetByName(pRegionName);
        if (age) {
            return age.offsetX + age.MaxWidth;
        } else {
            return 0;
        }
    }


    BuildItemsTree(itemsList: AgeScaleItem[]): AgeScaleItem[] {
        var root = [];
        for (var item of itemsList) {
            if (item.ParentId) {
                var parentItem = itemsList.find((tItem) => tItem.Id == item.ParentId);
                parentItem.itemsList.push(item);
            } else {
                root.push(item);
            }
        }
        root = this.SortArrayByAge(root);

        return root;
    }

    SortArrayByAge(itemsList: AgeScaleItem[]): AgeScaleItem[] {
        var ans = itemsList.sort((a, b) => a.MinAge - b.MinAge);

        for (var items of ans) {
            items.itemsList = this.SortArrayByAge(items.itemsList);
        }
        return ans;
    }


    static async LoadScale(pJsonUrl: string): Promise<AgeScale> {
        var query = await fetch(pJsonUrl);
        var pJsonObject = await query.json();
        var ans = new AgeScale(pJsonObject);
        return ans;
    }

    static async LoadScaleFromDict(maps2500: Maps2500Service, vocId: string, maxLevel: number = 0): Promise<AgeScale> {
        var scaleVocabulary = await maps2500.GetVocabulary(vocId);
        scaleVocabulary.termsList = await maps2500.GetTermsList(scaleVocabulary, false);
        var ans = new AgeScale(scaleVocabulary, maxLevel);
        return ans;
    }



}
