import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = true;
  isAuthenticated: boolean = false;
  isAdmin: boolean = false;
  showMenuCommon: boolean = false;

  UserName: any;

  constructor(
    private oidc: OidcSecurityService
  ) {
    this.oidc.isAuthenticated$.subscribe(isAuth => {
      this.UserName = null;
      this.isAdmin = false;
      this.isAuthenticated = isAuth;
      if (isAuth) {
        var profileInfo = this.oidc.getPayloadFromIdToken();
        this.UserName = profileInfo["given_name"] || profileInfo["email"];
        var payload = this.oidc.getPayloadFromIdToken();
        this.isAdmin = (payload && payload["roles"]) ? payload["roles"]?.indexOf("TK2500_ADMIN") != -1 : false;

        this.showMenuCommon = this.isAuthenticated && this.isAdmin;
      }

    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }


  login() {
    console.log("login");
    //this.oidc.authorizeWithPopUp();
    this.oidc.authorize();
  }

  logout() {
    console.log("logout");
    this.oidc.logoff();
  }
}
