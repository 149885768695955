import { VocabularyExport } from "./vocabularyExport";

class TermExport {
    termId: string;
    vocExpId: string;
    termCode: string;
    termName: string;
}

export class DictTerm {
    static IncludeTermToGroup(vTerm: DictTerm, selectedGroup: VocabularyExport) {
        var newTermExp = new TermExport();
        newTermExp.termId = vTerm.id;
        newTermExp.vocExpId = selectedGroup.vocExpId;

        if (!vTerm.termExports) vTerm.termExports = [];
        console.log(newTermExp);
        vTerm.termExports.push(newTermExp);
    }

    static ExcludeFromExportGroup(vTerm: DictTerm, selectedGroup: VocabularyExport) {
        var indx = vTerm.termExports.findIndex(exp => exp.vocExpId = selectedGroup.vocExpId);
        vTerm.termExports.splice(indx, 1);
    }

    static ContainText(nodeData: DictTerm, filterString: string): boolean {
        if (nodeData.termName?.toLocaleLowerCase().indexOf(filterString) != -1) return true;
        if (nodeData.termCode?.toLocaleLowerCase() == filterString) return true;
        if (nodeData.synonymsList) {
            for (var synName of nodeData.synonymsList) {
                if (synName?.toLocaleLowerCase().indexOf(filterString) != -1) return true;
            }
        }
        return false;
    }
    ContainText(filterString: string) {
        if (this.termName?.toLocaleLowerCase().indexOf(filterString) != -1) return true;
        if (this.termCode?.toLocaleLowerCase() == filterString) return true;
        if (this.synonymsList) {
            for (var synName of this.synonymsList) {
                if (synName?.toLocaleLowerCase().indexOf(filterString) != -1) return true;
            }
        }
        return false;
    }
    bcode: string;
    id: string;
    index1: string;
    index2: string;
    index3: string;
    lastChange: string;
    parentId: string;
    termCode: string;
    termDesc: string;
    termName: string;
    termOrder: number;
    termLevel: string;
    vocId: number;
    synonyms: string;
    synonymsList: string[];
    minValue: number;
    maxValue: number;

    items: DictTerm[];
    colorRgb: any;

    termExports: TermExport[];
}
