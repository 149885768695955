export class VocabularyExport {
    vocExpId: string;

    exportName: string;
    exportLogic: string;
    targetType: string;
    targertName: string;
    fieldCode: string;
    fieldName: string;
    filterCondition: string;
    lastUpdated: Date;
}
