import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Component({
  selector: 'app-fetch-data',
  templateUrl: './fetch-data.component.html'
})
export class FetchDataComponent {
  public txt: String;
  private http: HttpClient;

  constructor(
    pHttp: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private oidc: OidcSecurityService) {
    this.http = pHttp;
  }

  load1() {
    var accessToken = this.oidc.getToken();
    this.http.get(this.baseUrl + 'test1').subscribe(result => {
      this.txt = result.toString();
    }, error => {
      this.txt = error.toString();
      console.error(error)
    });

  }

  load2() {
    var accessToken = this.oidc.getToken();
    this.http.get(this.baseUrl + 'test2', {
      headers: { "Authorization": "Bearer " + accessToken }
    }).subscribe(result => {
      this.txt = result.toString();
    }, error => {
      this.txt = error.toString();
      console.error(error)
    });

  }

}

