import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.css']
})
export class FileFieldComponent implements OnInit {
  _model: string | any;
  _url: string;
  fileReplaced: boolean = false

  @Input("value") set model(value: string | any) {
    this._model = value;
    this.fileReplaced = value && !(typeof value === 'string' || value instanceof String);

    this._url = this.maps2500.GetFileUrl(this._model);
  }

  get model(): string | any {
    return this._model;
  }

  @Output("valueChange") modelChange = new EventEmitter<string | any>();

  @Input() edit: boolean = false;

  constructor(private maps2500: Maps2500Service) { }

  ngOnInit(): void {
  }



  async LoadFile(fileList: FileList) {
    if (fileList) {
      console.log("FileUpload -> files", fileList);
    }

    var firstFile = fileList[0];
    var fileUrl = this.model?.url ?? this.model;

    var contentReplaceObject: any = {
      "url": fileUrl,
      "fileObject": firstFile
    };
    this.fileReplaced = true;
    this.model = contentReplaceObject;
    this.modelChange.emit(this.model);
  }

  DeleteFile() {
    this.fileReplaced = false;
    this.model = null;
    this.modelChange.emit(this.model);
  }

}
