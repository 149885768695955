import { Component, OnInit, ViewChild } from '@angular/core';
import { ITreeOptions, TreeComponent, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { Field } from 'src/app/Model/System/field';
import { Layer } from 'src/app/Model/System/layer';
import { Map } from 'src/app/Model/System/map';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-struct',
  templateUrl: './structMain.component.html',
  styleUrls: ['./structMain.component.css']
})
export class StructComponent implements OnInit {

  public mapsList: Map[] = [];
  public layersList: Layer[] = [];
  public fieldsList: Field[] = [];

  public showMapDescription: boolean = false;
  public showLayerDescription: boolean = false;

  public selectedMap: Map = null;
  public selectedLayer: Layer = null;
  public selectedField: Field = null;


  public treeOptionsMaps: ITreeOptions = {
    displayField: 'mapName',
    childrenField: 'items'
  };
  public treeOptionsLayers: ITreeOptions = {
    displayField: 'layerName',
    childrenField: 'items',
    allowDrag: true
  };
  public treeOptionsFieds: ITreeOptions = {
    displayField: 'fieldName',
  };

  @ViewChild('treeMaps') public treeMaps: TreeComponent;
  @ViewChild('treeLayers') public treeLayers: TreeComponent;
  @ViewChild('treeFields') public treeFields: TreeComponent;

  constructor(private maps2500: Maps2500Service) { }

  async LoadMaps() {
    this.selectMap(null);
    this.selectLayer(null);

    this.mapsList = await this.maps2500.GetMapsList();
    this.treeMaps.treeModel.update()
  }

  onSelectMap(event) {
    var map: Map = <Map>event?.node?.data;
    this.selectMap(map);
  }

  async selectMap(map: Map) {
    this.selectedMap = map;
    this.showMapDescription = map ? true : false;
    this.selectLayer(null);

    this.layersList = await this.maps2500.GetLayersList(map);
    this.treeLayers.treeModel.update()
  }

  public async SaveMap(map: Map) {
    this.maps2500.SaveMap(map);
  }




  async onSelectLayer(event) {
    var layer: Layer = <Layer>event?.node?.data;
    this.selectLayer(layer);
  }

  async selectLayer(layer: Layer) {
    this.selectField(null);
    this.selectedLayer = layer;
    this.showLayerDescription = layer ? true : false; /// если слой пустой - то скрываем
    this.fieldsList = await this.maps2500.GetFieldsList(layer);

    // Пока закомментировал т.к. не проверял.
    // TODO: в будущем перед началом редактирования нужно будет подгружать свежую версию
    // var loadedLayer = await this.maps2500.GetLayer(layer.mapId, layer.layerId);
    // Object.assign(layer, loadedLayer);
  }

  public onLayerMove(event) {
    var movingNode: Layer = <Layer>event.node;
    var newParent: Layer = <Layer>event.to.parent;
    movingNode.parentId = newParent.layerId;
    this.maps2500.SaveLayer(movingNode);
  }

  public async SaveLayer(layer: Layer) {
    this.maps2500.SaveLayer(layer);
  }

  public async AddLayer(map: Map) {
    var layer = await this.maps2500.NewLayer(map);
    this.layersList.push(layer);
    this.selectLayer(layer);
  }

  async onSelectField(event) {
    var field: Field = <Field>event?.node?.data;
    this.selectField(field);
  }
  public async onSaveField(field: Field) {
    this.maps2500.SaveField(field);
  }

  selectField(field: Field) {
    this.selectedField = field;
  }

  public async AddField(layer: Layer) {
    var field = await this.maps2500.NewField(layer);
    this.fieldsList.push(field);
    this.selectField(field);
  }

  public async AutoAddFields(layer: Layer) {
    var refreshedFieldsList = await this.maps2500.AutoAddFields(layer);
    // this.fieldsList.push(...fieldsList);
    this.selectLayer(layer);
    this.selectField(refreshedFieldsList[0]);
  }


  ngOnInit(): void {
    this.LoadMaps();
  }

}
