import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Gk2500Service } from 'src/app/Gk2500.service';

@Component({
  selector: 'app-Publish',
  templateUrl: './Publish.component.html',
  styleUrls: ['./Publish.component.css']
})
export class PublishComponent implements OnInit {
  public resultTxt: string;

  constructor(private svc: Gk2500Service) {
  }

  ngOnInit() {
  }

  async updateBaseaStatic() {
    this.resultTxt = "Запущено обновление Shapefile";
    this.svc.UpdateShapeFileStatic()
      .then((isOk) => {
        if (isOk) {
          this.resultTxt = "Обновление успешно";
        } else {
          this.resultTxt = "Обновление завершено (с ошибкой?)";
        }
      })
      .catch((error) => {
        this.resultTxt = "Ошибка обновления: " + error;

      })
  }

  async updateBaseaDynamic() {
    this.resultTxt = "Запущено обновление Shapefile";
    this.svc.UpdateCorrelationDynamic()
      .then((isOk) => {
        if (isOk) {
          this.resultTxt = "Обновление успешно";
        } else {
          this.resultTxt = "Обновление завершено (с ошибкой?)";
        }
      })
      .catch((error) => {
        this.resultTxt = "Ошибка обновления: " + error;

      })
  }
  


}
