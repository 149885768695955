import { DictTerm } from "../System/dict-term";

export class AgeScaleItem {
    public itemsList: AgeScaleItem[] = [];
    public Id: number;
    public ParentId: number;
    public Name: string;
    public synonymsList: string[];

    public MinAge: number;
    public MaxAge: number;
    public Color: string;

    // Calculated parameters for visualising
    public MinSize: number = 1;

    public MaxWidth: number;
    public MaxDepth: number;
    public level: number;
    public offsetX: number = 0;

    
    constructor(pJson: DictTerm | any) {
        //Object.assign(this, pJson)

        this.Id = pJson.id ?? pJson["Id"];
        this.ParentId = pJson.parentId ?? pJson["ParentId"];
        this.Name = pJson.termName ?? pJson["Name"];
        this.synonymsList = pJson.synonymsList ?? pJson["synonymsList"];
        this.synonymsList = this.synonymsList?.map( str => str.toLocaleLowerCase());
        

        this.MinAge = pJson["MinAge"];
        this.MaxAge = pJson["MaxAge"];
        this.Color = pJson.colorRgb ?? pJson["Color"];
        this.MinSize = pJson["MinSize"] ?? 1;
    }

    public CalculateMaxDepth(pLevel = 0): number {
        this.level = pLevel;
        var maxDepth = 0;
        if (this.itemsList && this.itemsList.length > 0) {
            for (var item of this.itemsList) {
                var itemDepth = item.CalculateMaxDepth(pLevel + 1);
                if (itemDepth > maxDepth) {
                    maxDepth = itemDepth;
                }
            }
        }

        this.MaxDepth = maxDepth + 1;
        return maxDepth + 1;
    }

    public CalculateMaxWidth(offset: number = 0): number {
        this.offsetX = offset;
        if (this.itemsList && this.itemsList.length > 0) {
            var width = 0;
            for (var item of this.itemsList) {
                var itemWidth = item.CalculateMaxWidth(offset + width);
                width += itemWidth;
            }
            this.MaxWidth = width;
            if (this.MinSize > this.MaxWidth) this.MaxWidth = this.MinSize;
        } else {
            this.MaxWidth = this.MinSize;
        }
        return this.MaxWidth;

    }


}
