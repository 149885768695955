import { Component, Input, OnInit } from '@angular/core';
import { DictTerm } from 'src/app/Model/System/dict-term';
import { VocabularyExport } from 'src/app/Model/System/vocabularyExport';

@Component({
  selector: 'app-term-form',
  templateUrl: './term-form.component.html',
  styleUrls: ['./term-form.component.css']
})
export class TermFormComponent implements OnInit {

  @Input() public termTemplate: string;
  @Input() public term?: DictTerm = null;
  @Input("selectedGroup") public selectedGroup: VocabularyExport;

  public editMode: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  AddSynonym() {
    if (!this.term.synonymsList) {
      this.term.synonymsList = [];
    }
    this.term.synonymsList.push("");
  }

  DeleteSynonym(synIndex: number) {
    this.term.synonymsList.splice(synIndex, 1);
  }

  SetSynonym(synIndx: number, newValue: any) {
    this.term.synonymsList[synIndx] = newValue;
  }

  IsInGroup(vTerm: DictTerm) {
    if (!this.selectedGroup) return;
    var selectedVocExpId = this.selectedGroup.vocExpId;
    var exportIds = vTerm.termExports.filter(exp => exp.vocExpId == selectedVocExpId);
    var isInGroup = exportIds.length > 0;
    return isInGroup;

  }
  IncludeToExportGroup(vTerm: DictTerm, selectedGroup: VocabularyExport) {
    DictTerm.IncludeTermToGroup(vTerm, selectedGroup)
  }

  ExcludeFromExportGroup(vTerm: DictTerm, selectedGroup: VocabularyExport) {
    DictTerm.ExcludeFromExportGroup(vTerm, selectedGroup)
  }

}
