import { Component, OnInit } from '@angular/core';
import { Map } from 'src/app/Model/System/map';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-versions-main',
  templateUrl: './versions-main.component.html',
  styleUrls: ['./versions-main.component.css']
})
export class VersionsMainComponent implements OnInit {
  public mapsList: Map[] = [];

  constructor(private maps2500: Maps2500Service) { }

  async LoadMaps() {
    this.mapsList = await this.maps2500.GetMapsVersions();
  }

  async initVersion(map: Map, versionName: string) {
    await this.maps2500.InitMapVersion(map.mapId, versionName);
    await this.LoadMaps();
  }
  async updateVersion(map: Map, versionName: string) {
    await this.maps2500.UpdateMapVersion(map.mapId, versionName);
    await this.LoadMaps();
  }

  ngOnInit() {
    this.LoadMaps();
  }

}
