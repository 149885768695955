import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './Controller/nav-menu/nav-menu.component';

import { AppRoutingModule } from './app-routing.module';
import { AppAuthModule } from './app-auth.module';
import { AppMaterialModule } from './app-material.module';
import { TreeModule } from "@circlon/angular-tree-component";
import { TranTComponent } from './Controller/tran-t/tran-t.component';

import { SelectTaxonComponent } from './Controller/tran-t/select-taxon/select-taxon.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { StructComponent } from './Controller/system/struct/structMain/structMain.component';
import { DictionaryComponent } from './Controller/system/vocabulary/dictionary/dictionary.component';
import { TermFormComponent } from './Controller/system/vocabulary/term-form/term-form.component';
import { VocabularyFormComponent } from './Controller/system/vocabulary/vocabulary-form/vocabulary-form.component';
import { MapComponent } from './Controller/system/struct/map/map.component';
import { LayerComponent } from './Controller/system/struct/layer/layer.component';
import { AuditViewComponent } from './Controller/system/audit/audit-view/audit-view.component';
import { CorrelationsListComponent } from './Controller/system/correlation/correlations-list/correlations-list.component';
import { SelectMapLayerComponent } from './Controller/system/correlation/select-map-layer/select-map-layer.component';
import { CorrelationDetailsComponent } from './Controller/system/correlation/correlation-details/correlation-details.component';
import { VersionsMainComponent } from './Controller/system/versions/versions-main/versions-main.component';
import { VersionBuildComponent } from './Controller/system/versions/version-build/version-build.component';
import { VersionPublicationComponent } from './Controller/system/versions/version-publication/version-publication.component';
import { CorrTableMainComponent } from './Controller/CorrTable/corr-table-main/corr-table-main.component';
import { MatrixLegendComponent } from './Controller/CorrTable/matrix-legend/matrix-legend.component';
import { SpanFieldComponent } from './Controller/Common/span-field/span-field.component';
import { ListFieldComponent } from './Controller/Common/list-field/list-field.component';
import { FileFieldComponent } from './Controller/Common/file-field/file-field.component';
import { FieldComponent } from './Controller/system/struct/field/field.component';
import { ExportFormComponent } from './Controller/system/vocabulary/export-form/export-form.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    TranTComponent,
    SelectTaxonComponent,
    StructComponent,
    DictionaryComponent,
    TermFormComponent,
    VocabularyFormComponent,
    MapComponent,
    LayerComponent,
    AuditViewComponent,
    CorrelationsListComponent,
    SelectMapLayerComponent,
    CorrelationDetailsComponent,
    VersionsMainComponent,
    VersionBuildComponent,
    VersionPublicationComponent,
    CorrTableMainComponent,
    MatrixLegendComponent,
    SpanFieldComponent,
    ListFieldComponent,
    FileFieldComponent,
    FieldComponent,
    ExportFormComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppMaterialModule,
    AppRoutingModule,
    AppAuthModule,
    TreeModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  exports: [
    AppMaterialModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

