import { AgeScale } from "./age-scale";
import { HeadScale } from "./head-scale";

export class LegendCube {
    public Name: string;
    public Index: string;
    public Description: string;
    public ObjectsCount: number;
    public Color: string;
    public QueryCondition: string;

    public Age1: string;
    public Age2: string;
    public Region: string;

    // Positioning
    public x: number = 0;
    public y: number = 0;
    public height: number;
    public ColumnIndex: number = 0;
    cx: number;
    cy: number;
    cw: number;
    ch: number;
    minAgeIndex: number;
    maxAgeIndex: number;
    showInMatrx: boolean;

    constructor(pJson, pFieldName: string, pFieldAge1: string, pFieldAge2: string, pFieldRegion: string) {
        Object.assign(this, pJson);
        this.Name = pJson[pFieldName];
        this.Age1 = pJson[pFieldAge1];
        this.Age2 = pJson[pFieldAge2];
        this.Region = pJson[pFieldRegion];
    }

    CalculatePosition(scale: AgeScale, head: HeadScale) {
        var age1 = scale.GetByName(this.Age1);
        var age2 = scale.GetByName(this.Age2);
        var region = head.GetByName(this.Region);
        if (region != null) {
            this.x = region.offsetX + this.ColumnIndex;
        } else {
            this.x = 0;
        }
        if (age1) {
            this.Color = age1.Color;
            this.y = age1.offsetX;
            if (age2) {
                this.height = Math.abs(age2.offsetX + age2.MaxWidth - age1.offsetX);
            } else {
                this.height = 1;
            }
        } else {
            this.y = 0;
            this.height = 1;
        }
    }

    CalculateCoordinates(sizeW: number, sizeH: number) {
        this.cx = this.x * sizeW;
        this.cy = this.y * sizeH;
        this.cw = sizeW;
        this.ch = this.height * sizeH

    }




    static async Load(pJsonUrl: string, pFieldName: string, pFieldAge1: string, pFieldAge2: string, pFieldRegion: string): Promise<LegendCube[]> {
        var query = await fetch(pJsonUrl);
        var pJsonObjectsList = await query.json();

        var ans: LegendCube[] = [];
        for (var itemJson of pJsonObjectsList["Items"]) {
            var item = new LegendCube(itemJson, pFieldName, pFieldAge1, pFieldAge2, pFieldRegion);
            ans.push(item);
        }
        return ans;
    }
}
