import { Map } from "./map";

export class Field {
    fldId: string;
    layerId: number;
    fieldName: string;
    fieldTitle: string;
    dataType: string; /// enum
    fieldDescription: string;
    fieldDictionary: string;
    fieldExamples: string;
}
