import { NgModule, APP_INITIALIZER } from '@angular/core';
import { OidcConfigService, AuthModule, LogLevel } from 'angular-auth-oidc-client';


@NgModule({
    imports: [AuthModule.forRoot()],
    exports: [],
    providers: [OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService],
            multi: true,
        }]
})

export class AppAuthModule { }

export function configureAuth(oidcConfigService: OidcConfigService) {
    return () =>
        oidcConfigService.withConfig({
            stsServer: 'https://weblogin.vsegei.ru/auth/realms/master',
            redirectUrl: window.location.origin + "/",
            postLogoutRedirectUri: window.location.origin,
            clientId: 'gk2500tools',
            scope: 'openid profile',
            responseType: 'code',
            silentRenew: true,
            silentRenewUrl: `${window.location.origin}/silent-renew.html`,
            logLevel: LogLevel.Debug        
        });
}