import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Params } from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {TreeNode} from "@circlon/angular-tree-component";
import {Observable} from "rxjs";
import {TreeNodeTK} from "../Model/TreeNodeTK";



@Injectable({
  providedIn: 'root'
})
export class TrantNodeService {

  private baseUrl: string;
  private table: string;
  constructor(private http: HttpClient,
              @Inject('BASE_URL') baseUrl: string,
              private oidc: OidcSecurityService,
              private route: ActivatedRoute
              ) {
    this.table = this.route.snapshot.paramMap.get('table')
    // отслеживаем изменение параметра id
    route.paramMap.subscribe(params=>{
      this.table=params.get('table');
      console.log("node param", params);
      console.log("table", this.table);
    });
    this.baseUrl = baseUrl+ 'api/taxonnode/';
  }

  GetUrl():string{
    return this.baseUrl + this.table + '/';
  }

  GetAll(): Observable<TreeNodeTK[]> {
    // return this.http.get<TreeNode[]>(this.baseUrl)
    return this.http.get<TreeNodeTK[]>(this.GetUrl(), { headers: { "Authorization": "Bearer " + this.oidc.getToken() } })
  }
}
