import { Component, OnInit, ViewChild } from '@angular/core';
import { Correlation } from 'src/app/Model/System/correlation';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-correlations-list',
  templateUrl: './correlations-list.component.html',
  styleUrls: ['./correlations-list.component.css']
})
export class CorrelationsListComponent implements OnInit {
  public addNewMode: boolean = false;

  public corrList: Correlation[] = []
  public selectedCorrelation?: Correlation = null;
  protected newCorrelation: Correlation;

  constructor(private maps2500: Maps2500Service) {
    this.LoadCorralationsList();

  }
  async LoadCorralationsList() {
    this.corrList = await this.maps2500.GetCorrelationsList();
    this.selectedCorrelation = null;
  }

  async onSelectCorrelation(corr: Correlation) {
    this.selectedCorrelation = corr;
    this.LoadCorrelation(corr);
  }

  async LoadCorrelation(corr: Correlation) {
    //this.selectedCorrelation = await this.maps2500.GetCorrelationDetails(corr);
  }

  async StartAddNew() {
    this.addNewMode = true;
    this.newCorrelation = new Correlation();
  }
  EndAddNew() {
    this.addNewMode = false;
    this.newCorrelation = null;
  }

  async AddNew() {
    this.addNewMode = false;
    var newCorr = await this.maps2500.AddNewCorrelation(this.newCorrelation);
    this.LoadCorralationsList();
  }

  async DeleteCorrelation(corr: Correlation) {
    await this.maps2500.DeleteCorrelation(corr);
    this.LoadCorralationsList();
  }


  ngOnInit(): void {
  }

}