import {Component, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Gk2500Service } from "../../Gk2500.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ITreeOptions, ITreeState, TreeComponent, TreeModel, TreeNode } from "@circlon/angular-tree-component";
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from "@angular/router";
import { TranT } from "../../Model/trant";
import { TrantNodeService } from "../../Services/trant-node.service";
import { TranTServiceService } from "../../Services/tran-tservice.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

enum TaxonDetailState {
  NotSelected,
  Single,
  Multiple
}

@Component({
  selector: 'app-tran-t',
  templateUrl: './tran-t.component.html',
  styleUrls: ['./tran-t.component.css'],
  providers: [TrantNodeService, TranTServiceService]
})
export class TranTComponent implements OnInit {
  detaileState:TaxonDetailState= TaxonDetailState.NotSelected;
  needUpdate: boolean = false;
  nodes: any[] = [];


  isLoadTree: boolean = false;

  currTID;
  newTaxonName: string;
  modalRef: BsModalRef;

  @ViewChild(TreeComponent)
  trantTree: TreeComponent;

  @ViewChild('modal') parentSel;


  taxon: TranT;
  isAuthenticated: boolean = false;
  allowedEditTaxon: boolean = false;

  isShoewLvl: boolean=false;

  ngOnInit(): void {
    this.refreshTree();

  }

  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
    displayField: "name",
    scrollContainer: document.getElementById("treeWraper")
  };
  private trantNodeServiceService: TrantNodeService;
  private _tranTSrv: TranTServiceService;

  state: ITreeState;



  constructor(private modalService: BsModalService, private route: ActivatedRoute, tranNodeSrv: TrantNodeService, tranTSrv: TranTServiceService, private oidc: OidcSecurityService) {
    this.trantNodeServiceService = tranNodeSrv;
    this._tranTSrv = tranTSrv;
    // this.route=route;
    this.oidc.isAuthenticated$.subscribe(isAuth => {
      this.isAuthenticated = isAuth;
      this.allowedEditTaxon = this.allowedEditTaxon && isAuth;
    });

    this.oidc.userData$.subscribe(userData => {
      console.log("userData: ", userData);
      var userRoles =  userData.roles;
      //var userRoles = [];
      this.allowedEditTaxon = userRoles.includes("TK2500_RAYON_TAXON_ADMIN");
    });

    route.paramMap.subscribe(params=>{
      this.refreshTree();
    });

  }



  //#region TaxonCRUD
  addTaxonToRoot(){
    var body = new TranT();
    body.fullName = this.newTaxonName;
    body.parentTaxonId = null;
    this._tranTSrv.Add(body).subscribe(d => {
        this.newTaxonName="";
        this.refreshTree();
      }
      , err => alert(err.message));
  }

  addTaxon() {
    var treeModel= this.trantTree.treeModel;
    var parentTaxon =treeModel.getActiveNode();
    var parentTaxonID = parentTaxon ? parentTaxon.id : null;

    if (parentTaxon == null) {
      alert("Выдилите таксон в который нужно добавить новый")
      return
    }
    var body = new TranT();
    body.fullName = this.newTaxonName;
    body.parentTaxonId = parentTaxonID;
    //todo add taxon to tree before expand

    this._tranTSrv.Add(body).subscribe(d => {
        console.log("done");
        this.newTaxonName="";
        this.refreshTree();
        const someNode = treeModel.getNodeById(parentTaxonID);
        someNode.expand();
      }
      , err => alert(err.message));
    console.log("done2");
  }

  deleteTaxon() {
    if (confirm('Будет удален таксон '+ this.taxon.fullName + ' и все его дочерни таксоны. Удалить?')){
      this._tranTSrv.Delete(this.taxon.taxonId).subscribe(d => {
          console.log("done");
          this.refreshTree();
        }
        , err => alert(err));
      console.log("done2");
    }
  }

  taxonUpdate() {
    this._tranTSrv.Update(this.taxon).subscribe(d => {
        // this.taxon = d;
        console.log("done up");
        this.refreshTree();
      },
      err => alert("Не получается обновить таксон"), () => {

      })
  }

  getTaxon() {
    this._tranTSrv.GetByID(this.currTID).subscribe(
      d => this.taxon = d,
      err => alert(err.message))

  }
  //#endregion


  //#region Choice Parent Dialog

  onSelectedTrantID(curID: number) {
    this.parentSel = curID;
  }

  // showTaxonDlg() {
  //   this.parentSel.Modal('show')
  // }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-scrollable",
    });
  }

  // parentSelChange(curID:number){
  //   alert("ch")
  //   this.parentSel=curID;
  // }

  saveParent() {
    if (this.taxon == null) {
      alert("Ошибка! Таксон не выбран")
      return;
    }
    if (this.parentSel == null) {
      alert("Выберете таксон")
      return;
    }
    this.taxon.parentTaxonId = this.parentSel
    this.modalRef.hide();
  }
//#endregion


  //#region Tool action

  refreshTree(): void {
    this.isLoadTree = true;
    this.nodes=[]
    this.trantNodeServiceService.GetAll().subscribe(r => {
        this.nodes = r;
      }
      , err => alert("Не получается загрузить данные"));
    this.isLoadTree = false;
  }

  unselectAll() {
    this.state= { ...this.state,
      selectedLeafNodeIds : {}
    }
  }

  filterFn(value: string, treeModel: TreeModel) {
    var vu = value.toUpperCase();
    console.log(vu)
    // treeModel.filterNodes((n:TreeNode)=>  n.data.name != null && n.data.name.toUpperCase().indexOf(vu) === -1)
    treeModel.filterNodes((n: TreeNode) => {
      console.log(n.data.name)
      return n.id == vu || (n.data.name != null && n.data.name.toUpperCase().includes(vu))
    })
  }

  clearFltr() {
    var node = this.trantTree.treeModel.getActiveNode();
    this.trantTree.treeModel.clearFilter();
    this.trantTree.treeModel.collapseAll();

    if (node != null) {
      var n = this.trantTree.treeModel.getNodeById(node.id);
      n.focus(true);
      n.setActiveAndVisible();
      n.expand();
      this.trantTree.treeModel.update()
    }
    // this.trantTree.treeModel.;

  }
  //#endregion
  isShowOrdNum: boolean= true;

  onTreeChange(event: any) {
    var treeModel: TreeModel=this.trantTree.treeModel;
    // expandedNodeIds - dictionary of node IDs to booleans
    // selectedLeafNodeIds - dictionary of node IDs to booleans
    // activeNodeIds - dictionary of node IDs to booleans
    // hiddenNodeIds - dictionary of node IDs to booleans
    // focusedNodeId - node ID

    if (treeModel.selectedLeafNodes.length>0){
      // Выделено таксонов (1-N) через checkbox (режим пакетного редактирования)
      this.detaileState= TaxonDetailState.Multiple;
      this.currTID=null;
      this.taxon = null;

      return
    }
    else{  // Если галками таксоны не выделены работаем в режиме простого редактирования
      var activeNode= treeModel.getActiveNode();
      if (activeNode !=null){
        this.detaileState= TaxonDetailState.Single;
        if (this.currTID != activeNode.id) {
          this.taxon=null;
          this.currTID = activeNode.id;
          this.getTaxon()
        }
        return;
      }
    }
    this.detaileState= TaxonDetailState.NotSelected;
    this.currTID = null;
    this.taxon = null;
    console.log("null taxon")
    // alert(JSON.stringify(event))

  }

  onActivate(event: any) {

  }
}
