import { Layer } from "./layer";

export class Correlation {
    corrId: number;
    updated: string;
    satus: string;
    layerId1: number;
    layerId2: number;

    Layer1: Layer;
    Layer2: Layer;
}
