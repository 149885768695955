import { Component, Input, OnInit } from '@angular/core';
import { Map } from 'src/app/Model/System/map';
import { MapVersion } from 'src/app/Model/System/map-version';
import { TaskStatus } from 'src/app/Model/TaskStatus';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-version-build',
  templateUrl: './version-build.component.html',
  styleUrls: ['./version-build.component.css']
})
export class VersionBuildComponent implements OnInit {

  _map: Map = new Map();
  @Input() set map(value: Map) {
    this._map = value;
    this.urlGetStatusText = this.maps2500.GetBuildStatusTextUrl(this._map.mapCode);
    this.urlGetLastLog = this.maps2500.GetLastBuildLogtUrl(this._map.mapCode);
  }
  status: TaskStatus = null;
  urlGetStatusText: string;
  urlGetLastLog: string;

  constructor(private maps2500: Maps2500Service) {

  }

  ngOnInit(): void {
    this.UpdateBuildStatus();
  }

  async startBuildTask() {
    await this.maps2500.StartBuild(this._map.mapCode);
    this.UpdateBuildStatus();

  }
  async UpdateBuildStatus() {
    this.status = await this.maps2500.GetBuildStatus(this._map.mapCode);
  }

}
