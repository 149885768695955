import { Inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeadScale } from '../Model/matrixLegend/head-scale';
import { Legend } from '../Model/matrixLegend/legend';
import { LegendTable } from '../Model/matrixLegend/legend-table';
import { Correlation } from '../Model/System/correlation';
import { DictTerm } from '../Model/System/dict-term';
import { Field } from '../Model/System/field';
import { Layer } from '../Model/System/layer';
import { Map } from '../Model/System/map';
import { MapVersion } from '../Model/System/map-version';
import { Vocabulary } from '../Model/System/vocabulary';
import { TaskStatus } from '../Model/TaskStatus';

@Injectable({
  providedIn: 'root'
})
export class Maps2500Service {
  ///------------------  Files Interface   --------------------------
  async UploadFile(fileCode: string, file: File): Promise<string> {
    var fileContent = await Maps2500Service.readFileAsyncDataUrl(file);

    if (!fileCode) {
      fileCode = "";
    }
    var loadFileResponse = await this.Post("api/maps2500/file/" + fileCode + "?fileName=" + file.name, fileContent);
    var fileCodeAns = loadFileResponse.fileName;
    return fileCodeAns;

  }
  GetFileUrl(fileCode: string | File): string {
    if (fileCode instanceof File) {
      return "";
    }
    return "api/maps2500/file/" + fileCode;
  }

  ///------------------  Audit   --------------------------
  async GetAuditRecords(): Promise<any[]> {
    var ans = await this.Get("api/maps2500/audit/");
    return ans;
  }


  ///------------------  Matrix Legend --------------------------

  async GetMatrixLegends(): Promise<LegendTable[]> {
    var ans = <LegendTable[]>await this.Get("api/matrix/config/");
    return ans;
  }
  async GetMatrixRegion(mapCode: string, columnCode: string): Promise<HeadScale> {
    var headJson = <HeadScale>await this.Get("api/matrix/config/" + mapCode + "/" + columnCode + "/regions");
    var ans = new HeadScale({ "Items": headJson });
    return ans;
  }
  async GetMatrixCube(mapCode: string, columnCode: string, maxCount: number): Promise<Legend> {
    var legendJson = <Legend>await this.Get("api/matrix/config/" + mapCode + "/" + columnCode + "/cubes?maxCount=" + maxCount);
    var ans = new Legend(legendJson, "name", "ageMin", "ageMax", "region");
    return ans;
  }


  ///------------------  Structure  --------------------------

  async GetMapsList(): Promise<Map[]> {
    var ans = <Map[]>await this.Get("api/maps2500/maps/");
    return ans;
  }

  async SaveMap(map: Map) {
    var ans = <Map>await this.Post("api/maps2500/maps/" + map.mapId, map);
    return ans;
  }

  async GetLayersList(map: Map): Promise<Layer[]> {
    if (!map) {
      return [];
    }
    var mapId = map.mapId;
    var ans = <Layer[]>await this.Get("api/maps2500/maps/" + mapId + "/layers");
    ans = <Layer[]>this.BuildHierarchicalModel(ans, 'layerId', "parentId");
    return ans;

  }

  async GetLayer(mapId: number, layerId: number): Promise<Layer> {
    var ans = <Layer>await this.Get("api/maps2500/maps/" + mapId + "/layers/" + layerId);
    return ans;
  }
  async SaveLayer(layer: Layer): Promise<Layer> {
    /// Если при редактировании заменили файл заменяем файл
    if (layer.layerUrl && layer.layerUrl.fileObject && layer.layerUrl.fileObject instanceof File) {
      var fileUrl = await this.UploadFile(layer.layerUrl.url, layer.layerUrl.fileObject);
      layer.layerUrl = fileUrl;
    }

    var ans = <Layer>await this.Post("api/maps2500/maps/" + layer.mapId + "/layers/" + layer.layerId, layer);
    return ans;
  }
  async NewLayer(map: Map): Promise<Layer> {
    var newLayer = new Layer();
    newLayer.mapId = map.mapId;
    newLayer.layerName = "Новый";

    var ans = await this.CreateLayer(newLayer);
    return ans;
  }

  async CreateLayer(layer: Layer): Promise<Layer> {
    var ans = <Layer>await this.Post("api/maps2500/maps/" + layer.mapId + "/layers/", layer);
    return ans;
  }


  async GetFieldsList(layer: Layer): Promise<Field[]> {
    if (!layer) return [];
    var mapId = layer.mapId;
    var layerId = layer.layerId;
    var ans = <Field[]>await this.Get("api/maps2500/maps/" + mapId + "/layers/" + layerId + "/fields");
    return ans;
  }

  async SaveField(field: Field): Promise<Field> {
    var mapsId = "0"; /// сервер не проверяет чтобы был правильный mapId так что пишем туда любое значение.
    var layerId = field.layerId;
    var fieldId = field.fldId ?? ""; // если id - undefшned то нужно его заменить на пустоту. уначе JS так и вставляе - "/fields/undefined"
    var ans = <Field>await this.Post("api/maps2500/maps/" + mapsId + "/layers/" + layerId + "/fields/" + fieldId, field);
    return ans;
  }
  async NewField(layer: Layer): Promise<Field> {
    var newField = new Field();
    newField.layerId = layer.layerId;
    newField.fieldName = "NEW";
    newField.fieldTitle = "Новое поле";

    newField = await this.SaveField(newField);
    return newField;
  }
  async AutoAddFields(layer: Layer): Promise<Field[]> {
    if (!layer) return [];
    var mapId = layer.mapId;
    var layerId = layer.layerId;
    var ans = <Field[]>await this.Post("api/maps2500/maps/" + mapId + "/layers/" + layerId + "/autoAddFields", null);
    return ans;
  }




  ///------------------  Versions  --------------------------

  async GetMapsVersions(): Promise<Map[]> {
    var mapsList = await this.GetMapsList();

    for (var map of mapsList) {
      var versions = await this.GetMapVersions(map.mapId)
      map.ver_work = versions["work"];
      map.ver_operative = versions["operative"];
      map.ver_official = versions["official"];
      map.ver_nrs = versions["nrs"];
    }
    return mapsList;
  }


  async GetMapVersions(mapId: number): Promise<any> {
    var mapVersions: MapVersion[] = <MapVersion[]>await this.Get("api/maps2500/maps/" + mapId + "/version/");

    var ans = {};
    for (var version of mapVersions) {
      ans[version.code] = version;
    }
    return ans;
  }

  async InitMapVersion(mapId: number, versionCode: string): Promise<MapVersion> {
    var mapVersion: MapVersion = <MapVersion>await this.Get("api/maps2500/maps/" + mapId + "/version/" + versionCode + "/init");
    return mapVersion
  }
  async StartBuild(mapCode: string): Promise<TaskStatus> {
    var mapVersion: TaskStatus = <TaskStatus>await this.Get("api/maps2500/build/" + mapCode + "/start");
    return mapVersion
  }

  GetBuildStatusTextUrl(mapCode: string): string {
    return "/api/maps2500/build/" + mapCode + "/statustext";
  }
  GetLastBuildLogtUrl(mapCode: string): string {
    return "/api/maps2500/build/" + mapCode + "/lastbuildlog";
  }

  async GetBuildStatus(mapCode: string): Promise<TaskStatus> {
    var mapVersion: TaskStatus = <TaskStatus>await this.Get("api/maps2500/build/" + mapCode + "/status");
    return mapVersion
  }

  async UpdateMapVersion(mapId: number, versionCode: string): Promise<MapVersion> {
    var mapVersion: MapVersion = <MapVersion>await this.Get("api/maps2500/maps/" + mapId + "/version/" + versionCode + "/build");
    return mapVersion
  }
  async GetTaskStatus(taskCode: string): Promise<TaskStatus> {
    var mapVersion: TaskStatus = <TaskStatus>await this.Get("api/maps2500/task/" + taskCode + "/status");
    return mapVersion
  }


  ///------------------  Vocabulary --------------------------


  async GetTermsList(voc: Vocabulary, buildTree: boolean = true): Promise<DictTerm[]> {
    var vocId = voc.vocId;
    var ans = <DictTerm[]>await this.Get("api/maps2500/voc/" + vocId + "/terms");
    if (voc.isHierarchical && buildTree) {
      ans = <DictTerm[]>this.BuildHierarchicalModel(ans, "id", "parentId");
    }
    ans = this.SortTermsTree(ans);
    return ans;
  }


  async GetVocabulary(vocId: string): Promise<Vocabulary> {
    var ans = <Vocabulary>await this.Get("api/maps2500/voc/" + vocId);
    return ans;
  }

  async GetVocabularyList(): Promise<Vocabulary[]> {
    var ans = <Vocabulary[]>await this.Get("api/maps2500/voc/");
    ans = <Vocabulary[]>this.BuildHierarchicalModel(ans, "vocId", "parentVocId");
    return ans;
  }

  async SaveTerm(Vocabulary: Vocabulary, Term: DictTerm): Promise<DictTerm> {
    var url = "api/maps2500/voc/" + Vocabulary.vocId + "/terms/" + Term.id;
    Term.synonyms = Term.synonymsList.join(",");
    var ans: DictTerm = <DictTerm>await this.Post(url, Term);
    return ans;
  }

  async AddTerm(Vocabulary: Vocabulary, Term: DictTerm = null): Promise<DictTerm> {
    var url = "api/maps2500/voc/" + Vocabulary.vocId + "/terms/";
    Term.synonyms = Term.synonymsList.join(",");
    var ans: DictTerm = <DictTerm>await this.Post(url, Term);
    return ans;
  }
  async DeleteTerm(Vocabulary: Vocabulary, Term: DictTerm) {
    var url = "api/maps2500/voc/" + Vocabulary.vocId + "/terms/" + Term.id;
    var ans: DictTerm = await this.Delete(url);
    return ans;
  }

  async AddVocabulary(Vocabulary: Vocabulary): Promise<Vocabulary> {
    var url = "api/maps2500/voc/";
    var ans: Vocabulary = <Vocabulary>await this.Post(url, Vocabulary);
    return ans;
  }

  async SaveVocabulary(Vocabulary: Vocabulary): Promise<Vocabulary> {
    var url = "api/maps2500/voc/" + Vocabulary.vocId;

    Vocabulary.dictTerms = null;
    var ans = <Vocabulary>await this.Post(url, Vocabulary);
    return ans;
  }

  async VocabularyToDomain(Vocabulary: Vocabulary) {
    var url = "api/maps2500/voc/" + Vocabulary.vocId + "/updateDomain";
    var ans = <Vocabulary>await this.Post(url, Vocabulary);
    return ans;
  }


  ///------------------  Correlations --------------------------
  async GetCorrelationsList(): Promise<Correlation[]> {
    var ans = <Correlation[]>await this.Get("api/maps2500/correlations/");
    return ans;
  }

  async GetCorrelationDetails(corr: Correlation): Promise<Correlation> {
    var corrId = corr.corrId;
    var url = "api/maps2500/correlations/" + corrId;
    var ans = <Correlation>await this.Get(url);
    return ans;
  }

  async AddNewCorrelation(newCorrelation: Correlation): Promise<Correlation> {
    var url = "api/maps2500/correlations/new/" + newCorrelation.layerId1 + "/" + newCorrelation.layerId2;
    var ans: Correlation = <Correlation>await this.Post(url, null);
    return ans;
  }
  async DeleteCorrelation(corr: Correlation) {
    var url = "api/maps2500/correlations/" + corr.corrId;
    await this.Delete(url);
    return;
  }



  constructor(@Inject('BASE_URL') private baseUrl: string, private oidc: OidcSecurityService) {

  }

  BuildHierarchicalModel(termsList: any[], pFieldID = 'id', pFieldParent = 'parentId'): any[] {
    var termsTree = [];
    for (var term of termsList) {
      if (term[pFieldParent]) {
        var parentTerm = termsList.filter(t => t[pFieldID] == term[pFieldParent])[0];
        if (parentTerm) {
          if (!parentTerm.items) parentTerm.items = [];
          parentTerm.items.push(term);
        }
      } else {
        termsTree.push(term);
      }
    }
    return termsTree;
  }

  SortTermsTree(termsTree: DictTerm[]): DictTerm[] {
    var ans: DictTerm[] = [];
    ans = termsTree.sort((a, b) => {
      if (a.termOrder == b.termOrder)
        return (a.termName > b.termName ? 1 : -1);
      return a.termOrder - b.termOrder
    });
    for (var term of ans) {
      if (term.items && term.items.length > 0) {
        term.items = this.SortTermsTree(term.items);
      }
    }
    return ans;
  }

  async Get(url: string): Promise<any> {
    var url = this.baseUrl + url;
    var query = await fetch(url, { headers: { "Authorization": "Bearer " + this.oidc.getToken() } });
    if (query.ok) {
      var ans = await query.json();
      return ans;
    }
    if (query.status == 401) {
      throw new Error("Не достаточно прав");
    }
    throw new Error("error: " + query.statusText);
  }

  async Post(url: string, payload: any): Promise<any> {
    var url = this.baseUrl + url;

    var payloadContent = JSON.stringify(payload);
    var mimteType = "application/json"
    // if (payload instanceof ArrayBuffer) {
    //   payloadContent = payload;
    // } else {
    //   payloadContent = JSON.stringify(payload);
    //   mimteType = "application/octet-stream"
    // }

    var query = await fetch(url, {
      method: 'POST',
      body: payloadContent,
      headers: {
        "Content-Type": mimteType,
        "Authorization": "Bearer " + this.oidc.getToken()
      }
    });
    if (query.ok) {
      var ans = await query.json();
      return ans;
    }
    if (query.status == 401) {
      throw new Error("Не достаточно прав");
    }
    throw new Error("error: " + query.statusText);
  }

  async Delete(url: string): Promise<any> {
    var url = this.baseUrl + url;
    var query = await fetch(url, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.oidc.getToken()
      }
    });
    if (query.ok) {
      var ans = await query.json();
      return ans;
    }
    if (query.status == 401) {
      throw new Error("Не достаточно прав");
    }
    throw new Error("error: " + query.statusText);
  }



  static async readFileAsyncDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(<string>reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

}
