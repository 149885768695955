import { Component, OnInit, ViewChild } from '@angular/core';
import { ITreeOptions, TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { DictTerm } from 'src/app/Model/System/dict-term';
import { Vocabulary } from 'src/app/Model/System/vocabulary';
import { VocabularyExport } from 'src/app/Model/System/vocabularyExport';
import { Maps2500Service } from 'src/app/Services/maps2500.service';

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.css']
})
export class DictionaryComponent implements OnInit {
  public vocList: Vocabulary[] = [];
  public termsList: DictTerm[] = [];
  public selectedVocabulary?: Vocabulary = null;
  public selectedTerm?: DictTerm = null;
  public domainUpdateInProgress = false;
  public termsFilterString: string = "";

  public selectedExportGroup: VocabularyExport;

  public statusMessage: string = "";
  public messageStyle: string = "";

  public treeOptionsVoc: ITreeOptions = {
    displayField: 'vocName',
    childrenField: 'items',
    allowDrag: true
  };
  public treeOptionsTerm: ITreeOptions = {
    displayField: 'termName',
    childrenField: 'items',
    idField: 'id',
    allowDrag: true
  };

  @ViewChild('treeVocabulary') public treeVocabulary: TreeComponent;
  @ViewChild('treeTerm') public treeTerm: TreeComponent;

  constructor(private maps2500: Maps2500Service) {
    this.LoadVocabularyList();

  }

  private ResetState() {
    this.statusMessage = "";
    this.domainUpdateInProgress = false;
    this.onSelectTerm(null);
    this.termsList = [];
  }
  SetMessageError(err: string) {
    this.statusMessage = err;
    this.messageStyle = "msgError";
  }
  SetMessageInfo(err: string) {
    this.statusMessage = err;
    this.messageStyle = "msgInfo";
  }


  private filterDelayTimer = null;
  onSetFilter() {
    if (this.filterDelayTimer) {
      clearTimeout(this.filterDelayTimer);
      this.filterDelayTimer = null;
    }

    this.filterDelayTimer = setTimeout(() => {
      var filterString = this.termsFilterString?.toLocaleLowerCase();
      //this.treeTerm.treeModel.filterNodes(filterString, true);
      this.treeTerm.treeModel.filterNodes((node: TreeNode) => {
        var nodeData: DictTerm = node.data;
        return DictTerm.ContainText(nodeData, filterString);
      }, true);
      this.filterDelayTimer = null;
    }, 500);

  }

  async LoadVocabularyList() {
    this.vocList = await this.maps2500.GetVocabularyList();
    this.ResetState();
    this.treeVocabulary.treeModel.update()
  }

  async onAddVocabulary() {
    var newVocabulary = new Vocabulary();
    newVocabulary.vocName = "новый словарь";
    var newVoc = await this.maps2500.AddVocabulary(newVocabulary);
    this.vocList.push(newVoc);
    this.treeVocabulary.treeModel.update();
    this.LoadVocabulary(newVoc);
  }

  async onSelectVocabulary(event) {
    var voc: Vocabulary = <Vocabulary>event?.node?.data;
    this.LoadVocabulary(voc);
  }

  async LoadVocabulary(voc: Vocabulary) {
    if (!voc) {
      this.selectedVocabulary = null;
      this.ResetState();
      return;
    }

    var indx = this.vocList.indexOf(voc);

    voc = await this.maps2500.GetVocabulary(voc.vocId);
    this.vocList.splice(indx, 1, voc);

    this.selectedVocabulary = voc;
    this.ResetState();
    this.treeOptionsTerm.allowDrag = voc.isHierarchical == 'Y';

    this.termsList = await this.maps2500.GetTermsList(voc);
    this.treeTerm.treeModel.update()
  }

  public async SaveVoc(Vocabulary: Vocabulary) {
    try {
      var updatedVoc = await this.maps2500.SaveVocabulary(Vocabulary);
      Object.assign(Vocabulary, updatedVoc);
      this.SetMessageInfo("Словарь сохранен");
    } catch (err) {
      this.SetMessageError("Не удалось сохранить: " + err);
    }
  }


  public async VocToDomain(Vocabulary: Vocabulary) {
    this.domainUpdateInProgress = true;
    try {
      var updatedVoc = await this.maps2500.VocabularyToDomain(Vocabulary);
      Object.assign(Vocabulary, updatedVoc);
    } catch (err) {
      this.SetMessageError("Не удалось обновить домен: " + err);
    }
    this.SetMessageInfo("Обновлен домен");
    this.domainUpdateInProgress = false;
  }

  public onDictMove(event) {
    var movingNode: Vocabulary = <Vocabulary>event.node;
    var newParent: Vocabulary = <Vocabulary>event.to.parent;
    var newParentID = null;
    if (!event.to.parent.virtual) {
      /// virtual - значит переместили в самый корень.
      newParentID = newParent.vocId;
    }
    if (movingNode.parentVocId == newParentID) {
      /// Если родитель не менялся - нечего сохранять
      /// если менялся порядок - пока не реагируем.
      return;
    }

    movingNode.parentVocId = newParentID;
    if (confirm("переместить [" + movingNode.vocName + "] в качетсве дочернего к [" + newParent.vocName + "]")) {
      this.maps2500.SaveVocabulary(movingNode);
      this.SetMessageInfo("Термин перемещен");
    } else {
      this.LoadVocabularyList();
      this.SetMessageInfo("Перемещение отменено");
    }
  }

  public onTermMove(event) {
    var movingNode: DictTerm = <DictTerm>event.node;
    var newParent: DictTerm = <DictTerm>event.to.parent;
    var newParentID = null;
    if (!event.to.parent.virtual) {
      /// virtual - значит переместили в самый корень.
      newParentID = newParent.id;
    }
    if (movingNode.parentId == newParentID) {
      /// Если родитель не менялся - нечего сохранять
      /// если менялся порядок - пока не реагируем.
      return;
    }

    movingNode.parentId = newParentID;
    if (confirm("переместить [" + movingNode.termName + "] в качетсве дочернего к [" + newParent.termName + "]")) {
      this.maps2500.SaveTerm(this.selectedVocabulary, movingNode);
      this.SetMessageInfo("Термин перемещен");
    } else {
      this.LoadVocabulary(this.selectedVocabulary);
      this.SetMessageInfo("Перемещение отменено");
    }
  }

  public async AddTerm(Vocabulary: Vocabulary, parentTerm: DictTerm = null) {
    var newTerm: DictTerm = new DictTerm();
    newTerm.termName = "Новый";
    newTerm.parentId = parentTerm ? parentTerm.id : null;

    try {
      newTerm = await this.maps2500.AddTerm(Vocabulary, newTerm);

      if (parentTerm) {
        if (!parentTerm.items) {
          parentTerm.items = [];
        }

        this.treeTerm.treeModel.setExpandedNode(this.treeTerm.treeModel.getActiveNode(), true);
        parentTerm.items.push(newTerm);
      }
      else {
        this.termsList.push(newTerm);
      }

      await this.treeTerm.treeModel.update();
      var newNode = this.treeTerm.treeModel.getNodeBy(n => n.data.id == newTerm.id);
      if (newNode) {
        await this.treeTerm.treeModel.setSelectedNode(newNode, true);
        this.onSelectTerm({ node: newNode });
      }


      this.SetMessageInfo("Добавлен термин");
    } catch (err) {
      this.SetMessageError("Не удалось добавить термин: " + err);
    }
  }

  public async SaveTerm(Vocabulary: Vocabulary, Term: DictTerm) {
    try {
      var updatedTerm = await this.maps2500.SaveTerm(Vocabulary, Term);
      Object.assign(Term, updatedTerm);
      this.SetMessageInfo("Термин сохранен");
    } catch (err) {
      this.SetMessageError("Не удалось сохранить термин: " + err);
    }
  }
  public async DeleteTerm(Vocabulary: Vocabulary, Term: DictTerm) {
    if (Term.items && Term.items.length > 0) {
      alert("Невозможно удалить термин содержищий дочерние термины");
      return;
    }
    try {
      await this.maps2500.DeleteTerm(Vocabulary, Term);
      this.DeleteTerminFromList(Term, this.termsList);
      this.treeTerm.treeModel.update();
      this.SetMessageInfo("Термин удален");
    } catch (err) {
      this.SetMessageError("Не удалось удалить термин: " + err);
    }
  }

  DeleteTerminFromList(Term: DictTerm, list: DictTerm[] = null) {
    var indx = list.indexOf(Term);
    if (indx > -1) {
      list.splice(indx, 1);
    }

    for (var listTerm of list) {
      if (listTerm.items && listTerm.items.length > 0) {
        this.DeleteTerminFromList(Term, listTerm.items);
      }
    }
  }

  public onSelectTerm(event: any) {
    this.selectedTerm = null;
    var term: DictTerm = <DictTerm>event?.node?.data;
    this.selectedTerm = term;
  }

  ngOnInit(): void {
  }

}
