import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ITreeOptions, ITreeState, TreeComponent, TreeModel, TreeNode} from "@circlon/angular-tree-component";
import {Router} from "@angular/router";
import {TrantNodeService} from "../../../Services/trant-node.service";
import {TranT} from "../../../Model/trant";
import {TreeNodeTK} from "../../../Model/TreeNodeTK";

@Component({
  selector: 'app-select-taxon',
  templateUrl: './select-taxon.component.html',
  styleUrls: ['./select-taxon.component.css']
})
export class SelectTaxonComponent implements OnInit {
  @ViewChild(TreeComponent)
   trantTree: TreeComponent;
   taxon: TranT;
  tranNodeSrv:TrantNodeService;
  nodes: TreeNodeTK[];
  options: ITreeOptions = {

    displayField:"name",
    // scrollContainer: document.getElementById("treeWraper")
  };
  state: ITreeState;
  @Output() onSelectedTrantID = new EventEmitter<number>();
  // selectedTrantID: number




  constructor( tranNodeSrv:TrantNodeService) {
    this.tranNodeSrv = tranNodeSrv;
  }

  ngOnInit(): void {
    this.tranNodeSrv.GetAll().subscribe(r=>{
        this.nodes=r;
      }
      , err=>alert(err));
  }



  filterFn(value: string, treeModel: TreeModel) {
    var vu=value.toUpperCase();
    console.log(vu)
    // treeModel.filterNodes((n:TreeNode)=>  n.data.name != null && n.data.name.toUpperCase().indexOf(vu) === -1)
    treeModel.filterNodes((n:TreeNode)=>  {
      return n.id==vu || (n.data.name != null && n.data.name.toUpperCase().includes(vu))})
  }

  clearFltr() {
    var node= this.trantTree.treeModel.getActiveNode();
    this.trantTree.treeModel.clearFilter();
    this.trantTree.treeModel.collapseAll();

    if (node !=null) {
      var n = this.trantTree.treeModel.getNodeById(node.id);
      n.focus(true);
      n.setActiveAndVisible();
      n.expand();
      this.trantTree.treeModel.update()
    }
    // this.trantTree.treeModel.;

  }

  onStateChange(event: any) {

    var curNode:TreeNode=this.trantTree.treeModel.getActiveNode();
    if (curNode!= null){
      this.onSelectedTrantID.emit(curNode.id);
      // this.selectedTrantID=curNode.id;
    }else{
      this.onSelectedTrantID.emit(null);
      // this.selectedTrantID=null;
    }
  }
}
