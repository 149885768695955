import { Map } from "./map";

export class Layer {
    layerCode: string;
    layerId: number;
    layerName: string;
    layerDesc: string;
    layerTable: string;
    elementType: string; /// enum
    elementFormat: string; /// enum
    layerUrl: string | any;
    mapId: number;
    parentId: number;
    sdeVersion: string;
    Map?: Map

    items: Layer[];
}
